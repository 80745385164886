import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Vue from "vue";
import store from "../store/index";
import { checkAuthAndRedirect } from "@/api/auth-redirect";
import api from "@/api";

const DEFAULT_TITLE = "WebBuy";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "get-quote" */ "../views/Error.vue"),
  },
  {
    path: "/info",
    name: "Info",
    component: () =>
      import(/* webpackChunkName: "get-quote" */ "../views/Info.vue"),
  },
  {
    path: "/unauthorised",
    name: "Unauthorized",
    component: () =>
      import(/* webpackChunkName: "get-quote" */ "../views/Unauthorized.vue"),
  },
  {
    name: "GetQuote",
    path: "/get-quote",
    component: () =>
      import(
        /* webpackChunkName: "get-quote" */ "../views/FindProduct/FindProduct.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote",
    },
  },
  {
    name: "GetQuoteHasInsurance",
    path: "/get-quote/insurance",
    component: () =>
      import(
        /* webpackChunkName: "insurance" */ "../views/GetQuote/HasInsurance.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Insurance",
    },
  },
  {
    name: "GetQuotePostcode",
    path: "/get-quote/postcode",
    component: () =>
      import(
        /* webpackChunkName: "postcode" */ "../views/GetQuote/Postcode.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Postcode",
    },
  },
  {
    name: "GetQuoteBusinessOption",
    path: "/get-quote/business-option",
    component: () =>
      import(
        /* webpackChunkName: "business-option" */ "../views/FindProduct/Business.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Business",
    },
  },
  {
    name: "GetQuoteTreatmentOption",
    path: "/get-quote/treatment-option",
    beforeEnter: (to, from, next) => {
      if (!store.state.pageDecisions.coverType) {
        next("/get-quote");
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "treatment-option" */ "../views/FindProduct/CoverLevel.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Treatment options",
    },
  },
  {
    name: "GetQuoteCostcoMembership",
    path: "/get-quote/costco-membership",
    component: () =>
      import(
        /* webpackChunkName: "get-quote-costco-membership" */ "../views/GetQuote/CostcoMembership.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Costco",
    },
  },
  {
    name: "GetQuoteCoverLevel",
    path: "/get-quote/cover-level",
    beforeEnter: (to, from, next) => {
      if (!store.state.pageDecisions.coverType) {
        next("/get-quote");
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "cover-level" */ "../views/FindProduct/PrivateTreatmentCoverLevel.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Level of cover",
    },
  },
  {
    name: "GetQuoteExtendedFamilyCoverLevel",
    path: "/get-quote/extended-cover-level",
    beforeEnter: (to, from, next) => {
      if (!store.state.pageDecisions.coverType) {
        next("/get-quote");
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "cover-level" */ "../views/FindProduct/ExtendedFamilyCoverLevel.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Level of cover",
    },
  },
  {
    name: "GetQuoteInclude",
    path: "/get-quote/include",
    component: () =>
      import(
        /* webpackChunkName: "Include" */ "../views/FindProduct/Include.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Level of cover",
    },
  },
  {
    name: "GetQuoteRecommendation",
    path: "/get-quote/recommendation",
    beforeEnter: (to, from, next) => {
      if (!store.state.pageDecisions.coverType) {
        next("/get-quote");
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "recommendation" */ "../views/FindProduct/Recommendation.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Get a quote",
    },
  },
  {
    name: "FlexibleHealth",
    path: "/flexible-health",
    component: () =>
      import(
        /* webpackChunkName: "flexible-health" */ "../views/FindProduct/FlexibleHealth.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Level of cover",
    },
  },
  {
    name: "MeAndMyFamily",
    path: "/me-and-my-family",
    component: () =>
      import(
        /* webpackChunkName: "me-and-my-family" */ "../views/FindProduct/MeAndMyFamily.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Family",
    },
  },
  {
    name: "GetQuoteMoreAboutMembers",
    path: "/get-quote/family-members",
    component: () =>
      import(
        /* webpackChunkName: "family-members" */ "../views/GetQuote/MoreAboutMembers.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Member information",
    },
  },
  {
    name: "GetQuoteMoreAboutOccupation",
    path: "/get-quote/your-occupation",
    component: () =>
      import(
        /* webpackChunkName: "occupation" */ "../views/GetQuote/OccupationSelector.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Find product - Occupation",
    },
  },
  {
    name: "TellAboutHousehold",
    path: "/get-quote/multi-family-plan/tell-about-household",
    component: () =>
      import(
        /* webpackChunkName: "tell-about-household" */ "../views/MultifamilyPlan/TellAboutHousehold.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Family",
    },
  },
  {
    name: "MultiFamilyMoreAboutMembers",
    path: "/get-quote/multi-family-plan/family-members",
    component: () =>
      import(
        /* webpackChunkName: "multi-family-members" */ "../views/MultifamilyPlan/MoreAboutMembers.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Family",
    },
  },
  {
    name: "TellAboutHouseholdStep2",
    path: "/get-quote/multi-family-plan/tell-about-household-step2",
    component: () =>
      import(
        /* webpackChunkName: "tell-about-household-step2" */ "../views/MultifamilyPlan/TellAboutHouseholdStep2.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Family",
    },
  },
  {
    name: "QuotePolicyStart",
    path: "/quote/policystart",
    component: () =>
      import(
        /* webpackChunkName: "ApplyPolicyStart" */ "../views/GetQuote/PolicyStartDate.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Start date",
    },
  },
  {
    name: "CalculateMultiFamilyQuote",
    path: "/get-quote/multi-family-plan/calculate-quote",
    component: () =>
      import(
        /* webpackChunkName: "multi-family-plan-calculate-quote" */ "../views/MultifamilyPlan/CalculateMultiFamilyQuote.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Calculate",
    },
  },
  {
    name: "CalculateNHSTopUpQuote",
    path: "/get-quote/nhs-top-up-plan/calculate-quote",
    component: () =>
      import(
        /* webpackChunkName: "nhs-top-up-plan-calculate-quote" */ "../views/NHSTopUp/CalculateNHSTopUpQuote.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Calculate",
    },
  },
  {
    name: "CalculateNHSDentalQuote",
    path: "/get-quote/providental/calculate-quote",
    component: () =>
      import(
        /* webpackChunkName: "nhs-top-up-dental-calculate-quote" */ "../views/NHSDental/CalculateNHSDentalQuote.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Calculate",
    },
  },
  {
    name: "CalculateFlexibleHealthQuote",
    path: "/get-quote/flexible-health-plan/calculate-quote",
    component: () =>
      import(
        /* webpackChunkName: "flexible-health-plan-calculate-quote" */ "../views/FlexibleHealth/CalculateFlexibleHealth.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Calculate",
    },
  },
  {
    name: "CalculateCompleteHealthQuote",
    path: "/get-quote/complete-health-plan/calculate-quote",
    component: () =>
      import(
        /* webpackChunkName: "complete-health-plan-calculate-quote" */ "../views/CompleteHealth/CalculateCompleteHealth.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Get Quote - Calculate",
    },
  },
  {
    // AnaF 10/12/22 #98703 we need a different route because QuoteGeneral has 2 optional params and when you navigate the browser back button
    // we will end up with householdId = "-1" even though we previous route pushed was householdId = undefined and memberId = -1
    name: "QuoteGeneralHouseholdUndefined",
    path: "/get-quote/h/:memberId?",
    props: true,
    // props: (route) => {
    //   if (route.params.memberId != undefined) {
    //     const memberId = Number.parseInt(route.params.memberId, 10);
    //     return { memberId }; AnaF it doesn't work, it is still being received as a string
    //   }
    // },
    beforeEnter: (to, from, next) => {
      if (!store.getters.quote.householdOptions.length) next("/get-quote");
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "get-quote-general" */ "../views/GetQuote/QuoteGeneral.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Quote",
    },
  },
  {
    name: "QuoteGeneral",
    path: "/get-quote/quote/:householdId?/:memberId?",
    alias: ["/flexible-health/get-quote", "/multi-family/get-quote/:memberId?"],
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters.quote.householdOptions.length) next("/get-quote");
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "get-quote-general" */ "../views/GetQuote/QuoteGeneral.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Quote",
    },
  },
  {
    name: "QuoteCompleteHealth",
    path: "/get-quote/quote-CH/:householdId?/:memberId?",
    alias: ["/complete-health/get-quote"],
    props: true,
    beforeEnter: (to, from, next) => {
      if (!store.getters.quote.householdOptions.length) next("/get-quote");
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "get-quote-general" */ "../views/GetQuote/QuoteCompleteHealth.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Quote",
    },
  },
  {
    name: "GetQuoteReview",
    path: "/quote/review/:quoteId?",
    beforeEnter: (to, from, next) => {
      if (!store.getters.quote.householdOptions.length && !to.params.quoteId)
        next("/get-quote");
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "quote-review" */ "../views/GetQuote/Review.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Quote - Review",
    },
  },
  {
    name: "SaveQuote",
    path: "/quote/save/:quoteId?",
    component: () =>
      import(
        /* webpackChunkName: "hdi" */ "../views/GetQuote/ProcessQuote.vue"
      ),
    props: {
      action: "save",
    },
    meta: {
      title: "WPA Quote & Apply - Quote - Save",
    },
  },
  {
    name: "SendQuote",
    path: "/quote/send/:quoteId?",
    component: () =>
      import(
        /* webpackChunkName: "hdi" */ "../views/GetQuote/ProcessQuote.vue"
      ),
    props: {
      action: "send",
    },
    meta: {
      title: "WPA Quote & Apply - Quote - Send",
    },
  },
  {
    name: "ApplyQuote",
    path: "/quote/apply/:quoteId?",
    component: () =>
      import(
        /* webpackChunkName: "hdi" */ "../views/GetQuote/ProcessQuote.vue"
      ),
    props: {
      action: "apply",
    },
    meta: {
      title: "WPA Quote & Apply - Quote - Apply",
    },
  },
  {
    name: "SaveProgress",
    path: "/get-quote/save-progress",
    component: () =>
      import(
        /* webpackChunkName: "save-progress" */ "../views/Login/SaveProgress.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Quote - Save progress",
    },
  },
  {
    name: "Callback",
    path: "/callback",
    component: () =>
      import(/* webpackChunkName: "callback" */ "../components/callback.vue"),
    meta: {
      title: "WPA Quote & Apply - Finalazing login",
    },
  },
  {
    name: "CreateAccount",
    path: "/create-account",
    component: () =>
      import(
        /* webpackChunkName: "create-account" */ "../views/Login/CreateAccount.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Create an account",
    },
  },
  {
    name: "EnterPinForm",
    path: "/create-account/confirm",
    component: () =>
      import(
        /* webpackChunkName: "create-account" */ "../views/Login/EnterPinForm.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Create an account",
    },
  },
  {
    name: "CredentialsForm",
    path: "/create-account/complete",
    component: () =>
      import(
        /* webpackChunkName: "create-account" */ "../views/Login/CredentialsForm.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Create an account",
    },
  },
  {
    name: "MyProfile",
    path: "/my-profile",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "my-profile" */ "../views/MyProfile/index.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - My WPA",
    },
  },
  {
    name: "Login",
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
    meta: {
      title: "WPA Quote & Apply - Login",
    },
  },
  // Application pages - start
  {
    name: "ApplyInsurance",
    path: "/apply/insurance/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyHasInsurance" */ "../views/Apply/HasInsurance.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Current insurance",
    },
  },
  {
    name: "CurrentHealthInsurance",
    path: "/apply/current-health-insurance/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "CurrentHealthInsurance" */ "../views/Apply/SwitchFlow/CurrentHealthInsurance.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Current insurance",
    },
  },
  {
    name: "HasCertificate",
    path: "/apply/insurance-certificate/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "HasCertificate" */ "../views/Apply/SwitchFlow/HasCertificate.vue"
      ),
    meta: {
      title:
        "WPA Quote & Apply - Application - Current insurance - Certificate",
    },
  },
  {
    name: "SameCertificate",
    path: "/apply/same-certificate/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "HasSameCertificate" */ "../views/Apply/SwitchFlow/HasSameCertificate.vue"
      ),
    meta: {
      title:
        "WPA Quote & Apply - Application - Current insurance - Certificate",
    },
  },
  {
    name: "SameCertificateUpload",
    path: "/apply/same-certificate-upload/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "SameCertificateUpload" */ "../views/Apply/SwitchFlow/SameCertificateUpload.vue"
      ),
    meta: {
      title:
        "WPA Quote & Apply - Application - Current insurance - Certificate",
    },
  },
  {
    name: "UnderwritingTerms",
    path: "/apply/underwriting-terms/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "UnderwritingTerms" */ "../views/Apply/SwitchFlow/UnderwritingTerms.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Underwritting terms",
    },
  },
  {
    name: "UnderwritingTermsMembers",
    path: "/apply/underwriting-terms-members/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "UnderwritingTermsMembers" */ "../views/Apply/SwitchFlow/UnderwritingTermsMembers.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Underwritting terms",
    },
  },
  {
    name: "DefermentPeriod",
    path: "/apply/deferment-period/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "DefermentPeriod" */ "../views/Apply/SwitchFlow/DefermentPeriod.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Deferment period",
    },
  },
  {
    name: "ApplyInviteHook",
    path: "/invite/hook",
    component: () =>
      import(
        /* webpackChunkName: "ApplyInviteHook" */ "../views/Apply/InviteHook.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Invite",
    },
  },
  {
    name: "ApplyInvite",
    path: "/invite/:membershipRequestId",
    component: () =>
      import(/* webpackChunkName: "ApplyInvite" */ "../views/Apply/Invite.vue"),
    meta: {
      title: "WPA Quote & Apply - Invite",
    },
  },
  {
    name: "ApplyInviteStaticLink",
    path: "/join/:productId",
    component: () =>
      import(
        /* webpackChunkName: "ApplyInviteStaticLink" */ "../views/Apply/InviteStaticLink.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Invite",
    },
  },
  {
    name: "Apply",
    path: "/apply/qualification/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyDoYouQualify" */ "../views/Apply/DoYouQualify.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Do you qualify",
    },
  },
  {
    name: "ApplyMultifamily",
    path: "/apply/multifamily/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyUWDescription" */ "../views/Apply/Multifamily/ApplyMultifamily.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Multifamily",
    },
  },
  {
    name: "ApplyDependants",
    path: "/apply/dependants/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "StepDependants" */ "../views/Apply/StepDependants/StepDependants.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Family members",
    },
  },
  {
    name: "ApplyPolicyStart",
    path: "/apply/policystart/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyPolicyStart" */ "../views/Apply/PolicyStart.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Start date",
    },
  },
  {
    name: "ApplyAboutFamilyMember",
    path: "/apply/dependants/details/:appid/:member/:householdId",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "StepDependants" */ "../views/Apply/StepDependants/DependantDetails.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Family member",
    },
  },
  // Application pages - start
  // Apply - family member details
  {
    name: "ApplyAboutYou",
    path: "/apply/about-you/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "StepDependants" */ "../views/Apply/StepPersonalDetails/AboutYou.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Policyholder",
    },
  },
  {
    name: "ApplyPolicyName",
    path: "/apply/policyname/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyPolicyName" */ "../views/Apply/PolicyName.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Policy name",
    },
  },
  {
    name: "ApplyFamilyMemberDetails",
    path: "/apply/dependants/about/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyPersonalDetails" */ "../views/Apply/AboutFamilyMember.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Family member",
    },
  },
  {
    name: "ApplyHealthDetails",
    path: "/apply/health/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyHealthDetails" */ "../views/Apply/StepHealthDetails/StepHealthDetails.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health details",
    },
  },
  {
    name: "ApplyHealthCategories",
    path: "/apply/health-categories-uw/:uwStep/:appid",
    alias: ["/apply/health-categories/:appid/:hotfixMissing"],
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyHealthCategories" */ "../views/Apply/StepHealthDetails/HealthDetailsCategoriesList.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health questions",
    },
  },
  {
    name: "ApplySecondaryDetails",
    path: "/apply/health-details/:uwStep/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplySecondaryDetails" */ "../views/Apply/StepHealthDetails/SecondaryQuestionsList.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health questions",
    },
  },
  {
    name: "ApplyInviteCertificateUpload",
    path: "/apply/invite-certificate-upload/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyInviteCertificateUpload" */ "../views/Apply/StepHealthDetails/InviteCertificateUpload.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Certificate upload",
    },
  },
  {
    name: "ApplyPersonalDetails",
    path: "/apply/personal/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyPersonalDetails" */ "../views/Apply/StepPersonalDetails/StepPersonalDetails.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Personal details",
    },
  },
  {
    name: "ApplyLocation",
    path: "/apply/location/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyLocation" */ "../views/Apply/StepPersonalDetails/Location.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Address",
    },
  },
  {
    name: "ApplyOccupation",
    path: "/apply/occupation/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyOccupation" */ "../views/Apply/StepPersonalDetails/Occupation.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Occupation",
    },
  },
  {
    name: "ApplyGP",
    path: "/apply/gp/:appid/:memberid?",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyGP" */ "../views/Apply/StepPersonalDetails/GP.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - General practitioner",
    },
  },
  {
    name: "ApplyUWDescription",
    path: "/apply/uw-description/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyUWDescription" */ "../views/Apply/StepHealthDetails/UWDescription.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health details",
    },
  },
  {
    name: "ApplyHealthQuestion",
    path: "/apply/health-question/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyHealthQuestion" */ "../views/Apply/StepHealthDetails/HealthQuestion.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health questions",
    },
  },
  {
    name: "ApplyUWDescriptionLink",
    path: "/apply/uw-description-link/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyUWDescriptionLink" */ "../views/Apply/StepHealthDetails/UWDescriptionLink.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health details",
    },
  },
  {
    name: "ApplyPayment",
    path: "/apply/payment/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplyPayment" */ "../views/Apply/StepPayment/Payment.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Payment",
    },
  },
  {
    name: "ApplyMoratoriumUWDescription",
    path: "/apply/moratorium-uw-description/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        "../views/Apply/StepHealthDetails/MoratoriumUWDescription.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health details",
    },
  },
  {
    name: "SummaryReview",
    path: "/apply/review/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "SummaryReview" */ "../views/Apply/StepReview/SummaryReview.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Summary",
    },
  },
  {
    name: "DeclarationMain",
    path: "/apply/declaration/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "DeclarationMain" */ "../views/Apply/StepDeclaration/DeclarationMain.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Declaration",
    },
  },
  {
    name: "ApplicationComplete",
    path: "/apply/application-complete/:appid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ApplicationComplete" */ "../views/Apply/StepComplete/ApplicationComplete.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Complete",
    },
  },
  {
    name: "HDdet",
    path: "/apply/hd/:catid/:qfid",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "hdi" */ "@/views/Apply/StepHealthDetails/HealthFlowFqs.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Health questions",
    },
  },
  {
    name: "SubmitApplication",
    path: "/apply/submit",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "submitapplication" */ "@/views/Apply/SubmitApplication/SubmitApplication.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Submit",
    },
  },
  {
    name: "SubmitMembershipRequest",
    path: "/apply/submitMembershipRequest",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "submitmembershiprequest" */ "@/views/Apply/SubmitApplication/SubmitMembershipRequest.vue"
      ),
    meta: {
      title: "WPA Quote & Apply - Application - Submit",
    },
  },
  // Application pages - end
  // Renewal pages - start
  {
    name: "Renewals",
    path: "/renew",
    beforeEnter: async (to, from, next) => {
      const redirect = await checkAuthAndRedirect(to.path, store);
      if (redirect) {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "renewals" */ "@/views/Renewals/Renewals.vue"
      ),
    children: [
      {
        path: "overview",
        component: () =>
          import(
            /* webpackChunkName: "renewaloverview" */ "@/views/Renewals/RenewalOverview.vue"
          ),
        children: [
          {
            name: "RenewalsOverview",
            path: "",
            beforeEnter: (to, from, next) => next("/renew/overview/main"),
          },
          {
            name: "RenewalsMain",
            path: "main",
            component: () =>
              import(
                /* webpackChunkName: "renewaloverviewmain" */ "@/views/Renewals/RenewalOverviewMain.vue"
              ),
          },
          {
            name: "RenewalsCover",
            path: "cover",
            component: () =>
              import(
                /* webpackChunkName: "renewaloverviewcover" */ "@/views/Renewals/RenewalOverviewCover.vue"
              ),
          },
        ],
      },
      {
        name: "RenewalsBenefits",
        path: "benefits/:householdId/:memberId/:coverName?",
        component: () =>
          import(
            /* webpackChunkName: "cover" */ "@/views/Renewals/Covers/Cover.vue"
          ),
        props: (route) => {
          const householdId = Number.parseInt(route.params.householdId, 10);
          const memberId = Number.parseInt(route.params.memberId, 10);
          const coverName = route.params.coverName;

          if (Number.isNaN(householdId) || Number.isNaN(memberId)) {
            return { householdId: 0, memberId: 0, coverName };
          } else {
            return { householdId, memberId, coverName };
          }
        },
      },
      {
        name: "RenewalsDocuments",
        path: "policydocuments",
        component: () =>
          import(
            /* webpackChunkName: "policydocuments" */ "@/views/Renewals/PolicyDocuments.vue"
          ),
      },
      {
        name: "RenewalsQuotes",
        path: "renewalquotes",
        component: () =>
          import(
            /* webpackChunkName: "renewalquotes" */ "@/views/Renewals/RenewalQuotes.vue"
          ),
      },
      {
        name: "RenewalsConfirm",
        path: "confirmchanges",
        component: () =>
          import(
            /* webpackChunkName: "confirmchangestopolicy" */ "@/views/Renewals/ConfirmChangesToPolicy.vue"
          ),
      },
      {
        name: "RenewalsThankYou",
        path: "thankyou",
        component: () =>
          import(
            /* webpackChunkName: "renewalthankyou" */ "@/views/Renewals/RenewalThankYou.vue"
          ),
      },
    ],
  },
  // Renewal pages - end
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const initialTitle = document.title;
router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  if (!store.state.config) {
    const apiObj = await api;
    await store.commit("setConfig", apiObj.apiSpec);
    next();
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    try {
      document.title = to?.meta?.title || DEFAULT_TITLE;
    } catch {
      // do nothing, it's only matter of app insights telemetry
    }
  });
});

export default router;
